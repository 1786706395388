// shared messanger implementation for scope detail apps

let ctMessenger: undefined | (() => void) = undefined;

async function init(pCallbacks: ScopeFunctions) {
    const { GlobalMessenger } = await import('o365-modules');
    if (ctMessenger) { ctMessenger(); }
    ctMessenger = GlobalMessenger.on((pMessage: any) => {
        if (isScopeMessage(pMessage)) {
            if (pMessage.action == 'load' && pMessage.itemId && pCallbacks?.load) {
                pCallbacks.load(pMessage.itemId);
            }
        }
    });
}

function isScopeMessage(pObj: any): pObj is ScopeMessage {
    return pObj && typeof pObj == 'object' && pObj.type == 'scope';
}

type ScopeFunctions = {
    load: (pItemId: number) => void
}

type ScopeMessage = {
    type: 'scope',
    action: 'load',
    itemId: number,
}

export { init };